import React from 'react'

//import styles
import styles from './ImageWithParagraph.module.css'

//import components
import Line from './Line'
import Padding from './Padding'

export default ({background, title, text, color='white', align='center'}) => {
    const appliedStyles = {
        backgroundImage: `url(${background})`,
        justifyContent: align
    }
    return (
        <div className={styles.container} style={appliedStyles}>
            {align === 'start' ? <Padding /> : undefined}
            <div
                className={styles.content}
                style={{color}}
            >
                <Line color={color}/>
                <h3>{title}</h3>
                <p>{text}</p>
            </div>
        </div>
    )
}