import React from 'react'

//import components
import Project from '../components/Project'
import Padding from '../components/Padding'
import FullWidthImage from '../components/FullWidthImage'
import Image from '../components/Image'
import ImageWithParagraph from '../components/ImageWithParagraph'
import Grid from '../components/Grid'
import NextSections from '../components/NextSections'

// import images
import full from '../images/toothbrush-packaging/toothbrush-full.jpg'
import background from '../images/toothbrush-packaging/toothbrush-background.jpg'
import package1 from '../images/toothbrush-packaging/toothbrush-packaging-green.jpg'
import package2 from '../images/toothbrush-packaging/toothbrush-packaging-orange.jpg'
import package3 from '../images/toothbrush-packaging/toothbrush-packaging-red.jpg'
import logos from '../images/toothbrush-packaging/toothbrush-logos.jpg'
import logoSingle from '../images/toothbrush-packaging/toothbrush-logo-single.jpg'
import supporting from '../images/toothbrush-packaging/toothbrush-supporting.jpg'

export default () => (
    <Project
        title={'toothbrush packaging'}
            date={'2017'}
            position={'Student Project'}
            employer={`Rider University`}
            brief={[
                'The assignment was to develop packaging for a fictional product. The product I chose was a toothbrush that creates its own toothpaste in the bristles of the brush. The unique packaging was inspired by the triangular packaging of Toblerone chocolate. Therefore, I used a similar dieline to create the shape and incorporated the triangular element within the logo and graphics of the packaging to develop a cohesive and intriguing look.',
                'The product comes in three flavors: mint, peppermint, and orange, with corresponding packaging. I then created an advertisement mockup to show how it could be marketed.'
            ]}
            skills={[
                'Illustration & Layout Design',
                'Photo Manipulation'
            ]}
    >
        <FullWidthImage src={full} alt={'Magazine advertisment'} />
        <ImageWithParagraph
            background={background}
            title={'BRANDING'}
            text={`The pattern helped to underline the triangular branding of the Pasty Brush while creating an abstract visual, representing the act of cleaning. As the pattern progresses up the packaging, the colored triangles fade and become white, just as one's teeth would when using this product.`}
            color={'black'}
            align={'start'}
        />
        <Padding />
        <Grid
            images={[
                {
                    src: package1,
                    alt: 'Green-colored Mint Flavored Toothbrush Packaging'
                },
                {
                    src: package2,
                    alt: 'Orange-colored Orange Flavored Toothbrush Packaging'
                },
                {
                    src: package3,
                    alt: 'Red-colored Peppermint Flavored Toothbrush Packaging'
                }
            ]}
            columns={3}
        />
        <Padding />
        <Grid
            images={[
                {
                    src: logos,
                    alt: 'Six toothbrush logos'
                },
                {
                    src: logoSingle,
                    alt: 'Triangle with Pasty Brush text'
                }
            ]}
            columns={2}
            title={'LOGO DEVELOPMENT'}
            text={'The evolution of the Pasty Brush logo.'}
            color={'black'}
        />
        <Padding />
        <Image src={supporting} alt={'Toothbrush advertisment'} size={800} shadow={true}/>
        <Padding />
        <NextSections
            previous={'kids-swim-merchandise'}
            next={'visual-narratives'}
        />
    </Project>
)